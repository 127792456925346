@import '../../../../styles/variables';

.student {
    align-items: center;
    &Main {
        flex: 1;
    }
    button {
        margin-left: auto;
        color: $primary;
        &:hover {
            color: $body-color;
        }
    }
}