@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.progressWrapper {
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: .5rem 2rem;
    margin-bottom: 3rem;
    color: #fff;
    background: #006F81;
    &Write {
        background: linear-gradient(#20364B, #1D2671);
    }

    &Read {
        background: #006F81;
    }
    &Math {
        background: linear-gradient(#DB5610, #FF7300);
    }
}

.progress {
    display: flex;
    justify-content: space-between;  
    font-size: 1.8rem;
    align-items: center;

    &Indicator {
        background: $accent-3;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
        &Active {
            background: #fff;
            color: $body-color;
        }
        &Done {
            background: #fff;
            color: $primary;
        }
    }
    &Images {
        position: relative;
        display: flex;
        align-items: center;
        &Img {
            max-height: 30px;
            margin: .5rem 1rem;
        }
        &Brian {
            margin-top: -50px;
            margin-bottom: -50px;
            max-height: 55px;
            position: relative;
        }
    }

}
.progressMath {
    .progressIndicator {
        background: #545454;
    }
    .progressIndicatorActive,
    .progressIndicatorDone {
        background: #fff;
    }
}

@include breakpoint-up(md) {
    .progress {
        &Images {
            &Img {
                max-height: unset;
                max-width: 120px;
            }
        }
    }
}