@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.goReadDash {
    &Header {
        align-items: center !important;
        display: flex;
        flex-direction: row !important;
        justify-content: space-between !important;
        line-height: normal;
        margin-bottom: 20px;
    }

    &RefundContainer {
        background: $gray-1  !important;
    }

    &TextLink {
        align-items: center;
        color: $tertiary;
        display: flex;
        margin: 5px 0;
        cursor: pointer;

        &:hover {
            color: lighten($tertiary, 10%);
        }
    }
}

.trialEnd {
    background: #C6CBB5;
    padding: 2rem;
    border-radius: 1rem;
}

.trialBlock {
    background: #f1f1f1;
    border: 3px solid #FFD653;
}

.sideLock {
    border: 1px solid #4a4a4a;
    background: #ffd752;
    border-radius: 1rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    flex-direction: column;
}

.subFlex {
    display: flex;
    align-items: center;
}

.appLink {
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid #fff;
    flex-basis: 20rem;
    flex-grow: 0;
    flex-shrink: 1;
    padding-right: 3rem;
    margin-bottom: 2rem;

    &Side {
        color: lighten($body-color, 10%);
        font-size: 2rem;
        font-weight: bold;
        margin-left: 1rem;
        text-align: center;

        span {
            display: block;
        }
    }
}

.subPanel {
    background: #F3F3F3;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.subPanelAction {
    display: flex;
    align-items: center;
}

.subPanelBtn {
    margin-left: auto;
}

a.appLink:hover {
    background: $gray-1;

}

.uploadPanel {
    margin-top: 2rem;
}

.uploadForm {
    display: none;
}

.uploadLabel,
.successPanel {
    border-radius: 9px;
    color: #4a4a4a;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    padding: 6px 20px 6px 30px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
}

.uploadLabel:hover {
    color: lighten(#4a4a4a, 15%);
}

.uploadLabelLoading,
.uploadLabelLoading:hover {
    background: rgba(128, 184, 20, 0.6);
    cursor: wait;
    padding: 6px 20px;
}

.uploadIcon {
    position: absolute;
    left: 0;
    top: 3px;
}

.settingsHeader {
    display: flex;
    align-items: center;
}

.downloadData {
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    border-top: 1px solid #ccc;
    padding-top: 1.4rem;
    margin-top: 1rem;

    &:hover {
        color: $primary;
    }
}

.successPanel {
    background: #FFF;
    color: #80b814;
    font-weight: normal;
    text-transform: initial;
    font-size: 14px;
}

.uploadErrors {
    color: red;
    font-size: 12x;
    font-weight: bold;
    margin-top: 10px;
}

@include breakpoint-up(sm) {
    .goReadDash {
        &Header {
            border-radius: 0 !important;
            border-bottom: 1px solid $gray-1;
            padding-bottom: 20px !important;

            &Count {
                font-size: 2.0rem;
            }
        }

        &RefundContainer {
            padding: 2rem !important;
        }

    }

    .appLink {
        border-color: #ccc;

        &Side span {
            display: inline;
        }
    }

    .dashPan {
        border-bottom: 1px solid #CCC;
        padding-bottom: 20px;
    }
}

.adminAccess {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin: 1rem 0 3rem 0;
    > div {
        flex: 0 0 1;
    }
    svg {
        flex-basis: 5rem;
        max-width: 5rem;
    }
}
.roleCheckList {
    max-width: 40rem;
    li {
        padding: .6rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-child(even), &.striped {
            background: #f3f3f3;
        }
    }
}


@include breakpoint-up(md) {

    .goReadDash {
        &Content {
            display: flex;
            max-width: 1000px;
        }

        &Left {
            flex: 5;
        }

        &Right {
            flex: 3;
            margin-left: 40px;
        }
    }
}

.cancel {
    max-width: 90rem;
    &Header {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &Panel {
        padding: 1.6rem;
        border-radius: 1rem;
        background: lighten($gray-1, 10%);
        max-width: 40rem;
    }
}


.cancelledLabel {
    background: red;
    color: #FFF;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 10px 0;
}

.billingItem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CCC;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.switchBtn {
    color: #fff;
    background: #333;
    border-radius: 10rem;
    padding: 3px 8px!important;
    display: flex;
    border: none;
    align-items: center;
    span {
        width: 18px;
        height: 18px;
        background: #fff;
        margin-right: 0.5rem;
        border-radius: 1rem;
    }
    &Active {
        background: $primary;
    }
}