@import '../../../../styles/mixins';

.container {
    display: flex;
    max-width: 110rem;
}

.classRow {
    border: 1px solid #CCC;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.classLink {
    color: #4a4a4a;
}


.pageHeader {
    margin: 0 -30px 20px -30px;
    padding: 0 30px 20px 30px;
    border-bottom: 1px solid #CCC;
    display: flex;
    align-items: center;
}

.pageHeading {
    margin: 0;
}

.vidContainer {
    margin: 20px 0;
    max-width: 700px;
}

.backButton {
    border-radius: 100px;
    border: 1px solid #ccc;
    display: block;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a4a4a;
    margin-right: 10px;
    &:hover {
        background: rgb(231, 231, 231);
    }
}

.greySide {
    background: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
}

.studentRow {
    border-bottom: 1px solid #CCC;
    padding: 10px 0;
    display: flex;
    align-items: center;
}
.checkbox {
    margin-right: 5px;
}

.controls {
    display: flex;
    align-items: center;
    margin: 10px 0;
    position: relative;
}
.left {
    flex-grow: 1;
}
.right {
    margin-left: 40px;
    max-width: 35rem;
}

.actionDrop {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #CCC;
    border-radius: 10px;
    padding: 5px 10px;
    width: 150px;
    position: relative;
    &:hover {
        background: darken(#FFF, 5%);
    }
    &Disabled {
        background: darken(#FFF, 5%);
        cursor: default;
    }
    &Student {
        border: none;
    }
    &Title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-basis: 12rem;
    }
}

.actionDropHover {
    position: absolute;
    background: #FFF;
    top: 40px;
    border: 1px solid #CCC;
    z-index: 100;
    padding: 10px;
    font-size: 12px;
    width: 380px;
    left: 0;
    display: none;
}

.actionDrop:hover .actionDropHover  {
    display: block;
}

@include breakpoint-up(lg) {
    .container {
        &AddClass {
            max-width: 38rem!important;
            flex-basis: 38rem!important;
        }
    }
}


.maxTextWidth {
    max-width: 650px;
}

.regClassCheck {
    display: flex;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 20px 0;
}

.regClassSideCheck {
    padding: 10px;
    border: 1px solid #000;
    border-radius: 10px;
    text-align: center;
}

.regCount {
    margin-right: 10px;
    padding: 5px;
    border-radius: 10px;
}

.backLink {
    display: flex;
    align-items: center;
    max-width: 340px;
    color: #000;
    margin: 10px 0;
}
.backLinkIcon {
    margin-right: 10px;
}
.backLinkText {
    font-size: 18px;
    line-height: 24px;
}

.groupRow {
    display: flex;
    border: 1px solid #CCC;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    .classRow {
        flex-grow: 1;
        border: none;
        margin-bottom: 0;
        padding-left: 0;
        margin-left: 12px;
    }
}

.actionDropDown {
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 40px;
    left: 30px;
    background: #FFF;
    box-shadow: 0px 2px 3px rgba(0,0,0,0.3);
    width: 200px;
}

.actionBtn {
    display: block;
    width: 100%;
    border: none;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        background: #FAFAFA;
    }
}
.actionBtnBorder {
    border-bottom: 1px solid #CCC;

}