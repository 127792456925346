$brand-colors: (
    facebook: #3b5998,
    twitter: #1da1f2,
    googleplus: #dd4b39,
    linkedin: #0077b5,
    reddit: #ff4500,
    whatsapp: #128c7e,
);

.share {

    display: inline-block;
    margin-bottom: 1rem;

    &Layout {
        display: flex;
        justify-content: center;
    }

    &Btn {
        padding: 1rem;
        margin: 1rem;
        color: #fff;
        border-radius: 50%;
        border: none;
        display: block;
        cursor: pointer;

        svg {
            display: block;
        }

        @each $class,
        $color in $brand-colors {
            &.#{$class} {
                background-color: $color;

                &:hover {
                    background-color: #fff;
                    color: $color
                }
            }
        }
    }


}