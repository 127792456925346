@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);
  @include breakpoint-up($point, $grid-breakpoints) {
    @each $prop, $abbr in $space-properties {
      @each $size, $len in $spacers {
        .u-#{$abbr}-top#{$infix}-#{$size},
        .u-#{$abbr}-y#{$infix}-#{$size} {
          #{$prop}-top: $len !important;
        }

        .u-#{$abbr}-base#{$infix}-#{$size},
        .u-#{$abbr}-y#{$infix}-#{$size} {
          #{$prop}-bottom: $len !important;
        }
        .u-#{$abbr}#{$infix}-#{$size} {
            #{$prop}: $len !important;
        }
        .u-#{$abbr}-left#{$infix}-#{$size} {
            #{$prop}-left: $len !important;
        }
        .u-#{$abbr}-right#{$infix}-#{$size} {
            #{$prop}-right: $len !important;
        }
        .u-px#{$infix}-#{$size} {
          padding-right: $len !important;
          padding-left: $len !important;
        }
      }
    }

    .u-m#{$infix}-auto { margin: auto !important; }
    .u-m-top#{$infix}-auto,
    .u-m-y#{$infix}-auto {
      margin-top: auto !important;
    }
    .u-m-right#{$infix}-auto,
    .u-m-x#{$infix}-auto {
      margin-right: auto !important;
    }
    .u-m-base#{$infix}-auto,
    .u-m-y#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .u-m-left#{$infix}-auto,
    .u-m-x#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}
.u-m-top-10 {
  margin-top: 10px;
}
.u-m-base-15 {
  margin-bottom: 15px;
}

.u-m-top-30 {
  margin-top: 30px;
}

