.button {
    background: #80b814;
    border: 1px solid #80b814;
    border-radius: 9px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    padding: 6px 20px;
    text-transform: uppercase;
    &:hover {
        background: lighten(#80b814, 5%);
    }

    &OutlineBorder {
        background: none;
        color: #FFF;
        border: 1px solid #FFF;
    }
    &Outline {
        background: #ffffff;
        color: #80b814;
        &:hover {
            background: #f2f2f2;
        }
    }
    &:disabled {
        background: #d4d4d4;
        border-color: #d4d4d4;
        color: #979797;
        pointer-events: none;
    }
    &FullWidth {
        text-align: center;
        width: 100%;
    }
    &Large {
        font-size: 28px;
        line-height: 42px;
    }
}