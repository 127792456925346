a[disabled] {
    pointer-events: none;
}

.link-reverse {
    color: $body-color;
    &:hover {
        color: $primary;
    }
}
.link-underline {
    text-decoration: underline;
}

.btn-reset {
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: left;
    &:focus {
        outline: none;
    }
}
.btn-small {
    font-size: 1.4rem!important;
    padding: .3rem 1rem!important;
}

.btn-icon {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    svg {
        margin-right: .5rem;
    }
}
.btn-icon-large {
    font-size: 1.8rem;
}

.btn-danger {
    background: $accent-3!important;
    border-color: $accent-3!important;
    &:hover {
        border-color: lighten($accent-3, 10%)!important;
        background:lighten($accent-3, 10%) !important;
    }
}
.btn-outline-muted,
.btn-outline-danger {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0.2rem 1rem;
    &:disabled {
        opacity: .5;
        cursor: default;
        pointer-events: none;
    }

}

.btn-outline-danger {
    border-color: $accent-3;
    color: $accent-3;
}