@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.numStudents {
    align-items: center;
    display: flex;
}

.studentInput {
    border: 1px solid #D8DCCC;
    border-radius: 10px;
    margin-left: auto;
    padding-left: 5px;
    width: 70px;
}

.totalPanel {
    background: #FAFAFA;
    border-radius: 10px;
    padding: 15px;
}

.subTotal {
    display: flex;
    margin-left: auto;
    text-align: right;
    width: 80px;
}

.subTotalLeft {
    margin-right: auto;
}

.checkSet {
    align-items: center;
    display: flex;
}
.checkLabel {
    margin-left: 5px;
}

@include breakpoint-down(xs) {
    .purchaseContainer {
        background: #FFF;
        border-radius: 10px;
        padding: 15px;
    }
}

@include breakpoint-down(md) {
    .totalPanel {
        margin: 20px 0;
    }
}