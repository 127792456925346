@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.renewHeader {
    margin-bottom: 1rem;
    border-radius: 2rem;
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    background: linear-gradient(#FEF1EF, #E1E1E1);
}
.renewPanel {
    border-radius: 1rem;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    &St {
        font-size: 3.2rem;
        &Btn {
            font-size: 1.6rem;
            display: inline-flex;
            align-items: center;
        }
    }
    &Duration {
        color: #fff;
        font-weight: bold;
        font-size: 2rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
        border-radius: 3px;
        overflow: visible;
        background: #1D2671 url('../../../../images/calendar-savings.png') no-repeat right 4px top -2px;
        background-size: 48px 42px;
        &Inner {
            border-radius: 3px;
            background: #fff;
            margin-right: 70px;
            clip-path: polygon(0% 0%, 97% 0%, 100% 50%, 97% 100%, 0% 100%);
        }
        &Inner2 {
            border-radius: 3px;
            padding: .5rem 1rem;
            background: linear-gradient(45deg, #FF00C1, #E000E3);
            clip-path: polygon(0% 0%, 96% 0%, 99% 50%, 96% 99%, 0% 99%);
        }
    }
}
.bannerTwoYears {
    cursor: pointer;
}
.paymentSummaryFull {
    max-width: none;
}
.invoicePanel {
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid #696868;
}

.renewDiscount {
    padding: .5rem 1rem;
    text-align: center;
    margin-bottom: 1rem;
    background: #54B9C8;
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 4rem);
}
.renewStrike {
    text-decoration: line-through;
}

.priceChange {
    background: #CCA17A;
    padding: .5rem 1rem;
    text-align: center;
    font-weight: bold;
    color: #fff;
}