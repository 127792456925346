.container, .step5Inner {
    max-width: 700px;
}

.stepContainer {
    padding-bottom: 100px;
}

.stepFooter {
    background: #F7F7F7;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 100px;
    padding: 10px;
    display: flex;
    justify-content: right;
}

.updateProgress {
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
    flex-direction: column;
}


.archiveList {
    padding-bottom: 10px;
    border-bottom: 1px solid #CCC;
    margin-top: 10px;
    max-width: 600px;
}

.successPara {
    display: flex;
    align-items: center;
}

.addedStudent {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CCC;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.yGroup {
    color: #989595;
    margin-right: 10px;
}

.upn {
    margin-left: auto;
}

.endContainer {
    max-width: 900px;
    display: flex;
}

.endImg {
    margin-left: 20px;
}

.step1 {
    display: flex;
}

.step1Inner {
    max-width: 700px;
    margin-right: 40px;
}

.side {
    max-width: 400px;
    background: #F7F7F7;
    padding: 20px;
    border-radius: 10px;
}