@import '../../styles/mixins';
@import '../../styles/variables';


.container {
    max-width: 800px;
}
@include breakpoint-up(md) {
    .container {
    &Side {
        max-width: 21rem!important;
        flex-basis: 21rem!important;
        margin-right: 3rem;
    }
}
}
.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    &Left {
        display: flex;
        align-items: center;
    }
}

.inactiveBar {
    background: #E1E1E1;
    margin: 15px -20px 0 -20px;
    width: calc(100% + 40px);
    display: flex;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    &Info {
        align-items: center;
        display: flex;
        font-weight: bold;
    }
}
.roleLabels {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.roleLabel {
    padding: .2rem .8rem;
    border: 1px solid #FFD752;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    margin: 0 .4rem;
    svg {
        margin-right: .4rem;
    }
}

.requestItem {
    background: #F1f1f1;
    margin-bottom: 1px;
    padding: 5px 10px;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
}