@import '../../../../../../styles/variables';

.optionsContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255,255,255,0.2);
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel {
    border: 1px solid rgb(136, 136, 136);
    background: #FFF;
    display: flex;
}
.panelLeft {
    padding: 20px;
    flex-grow: 1;
    min-width: 300px;
}
.panelRight {
    padding: 20px;
    background: #efefef;
}

.moveOptLink {
    cursor: pointer;
    padding: .5rem 0;
    &:hover {
        color: $primary;
    }
}