@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.payment {
    &GreyPanel {
        background: $gray-1;
        border-radius: 1rem;
        padding: 2rem;
        margin-bottom: 2rem;
    }
    &GrandTour {
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
        border-radius: 1rem;
        padding: 2rem;
        margin-bottom: 2rem;
        background: #fff;
    }

    &FormTop {
        margin-bottom: 10px;
    }
    &Summary {
        margin-bottom: 2rem;
        max-width: 40rem;
        &Item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: .2rem 0;
            &Total {
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding: .5rem 0;
                margin-top: .5rem;
                font-weight: bold;
                margin-bottom: 2rem;
            }
        }
        &Data {
            display: flex;
            flex-basis: 8rem;
            justify-content: space-between;

            &Value {
                flex: 1;
                text-align: right;
            }
        }
    }
    &Address {
        font-style: normal;
        line-height: 1.3;
        margin-bottom: 2rem;
    }
}

@include breakpoint-up(md) {
    .payment {
        &Summary {
            &Holder {
                flex: 4;
                margin: 0 20px;
            }
        }
    }
}

.subType {
    &Link {
        margin-bottom: 2rem;
        padding: 1.4rem;
        border-radius: 1rem;
        border: 1px solid #e4e4e4;
        box-shadow: 0 0.1rem .3rem 0 rgba(0, 0, 0, 0.1);
        &Active {
            border-color: $accent-3;
        }
    }
    &Main {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }
    &Check {
            margin-right: 1rem;
            color: #E1E1E1;
            &Active  {
                color: $accent-3;
            }
        }
    &Content {
        margin: 1rem 0 0 2.5rem;
    }
}
.infoPanel {
    padding: 2rem;
    border-radius: 1rem;
    background: #FAFAFA;
    margin-bottom: 1rem;
}

.cardHolder {
    border: 1px solid #CCC;
    padding: 10px;
    border-radius: 10px;
    max-width: 600px;
}

.disclaimer {
    font-size: 12px;
}