.list-group {
    .list-item {
        padding: .5rem 0;
        border-bottom: 1px solid lighten($gray-1, 5%);
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &:last-child {
            border-bottom: none;
        }
        &--disabled {
            opacity: 0.6;
            cursor: default;
            pointer-events: none;
        }
        &--link {
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
        &--full {
            flex: 0 0 100%;
        }
    }
}

.side-nav-list {
    list-style: none;
    margin-bottom: 2rem;
    .side-nav-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: none;
        outline: none;
        border-bottom: 1px solid #E1E1E1;
        padding: 1rem .5rem;
        width: 100%;
        &-active {
            background: #E1E1E1;
        }
    }
}

