@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.appLink {
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid #fff;
    flex-basis: 20rem;
    flex-grow: 0;
    flex-shrink: 1;
    padding-right: 3rem;
    margin-bottom: 2rem;
    &:hover {
        background: $gray-1;
    }
    &Text {
        color: lighten($body-color, 10%);
        font-size: 2rem;
        font-weight: bold;
        margin-left: 1rem;
        text-align: center;
        span {
            display: block;
        }
    }
}


.actPanel {
    background: #FFF;
    border-radius: 15px;
    padding: 20px;
    margin-top: 20px;
    position: relative;
}
.actPanelHead {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.editActLink {
    cursor: pointer;
}

.statusLabel {
    background: #C6CBB5;
    border-radius: 5px;
    color: #FFF;
    padding: 2px 5px; 
    font-size: 18px;
    margin-left: 1rem;

}

.helpLink {
    color: #9DC030;
    cursor: pointer;
}

@include breakpoint-up(sm) {
    .appLink {
        border-color: #ccc;
        justify-content: flex-start;
        &Text span {
            display: inline;
        }
    }

    .actPanel {
        padding: 0;
    }
}

@include breakpoint-up(md) {
    .actPanel {
        max-width: 400px;
        margin-left: 40px;
    }
}