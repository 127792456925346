.signup {
    &Captcha {
        margin: 0 auto 2rem auto;
        text-align: center;
    }

    &Col {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

.signupWrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    padding: 3rem;
    text-align: left;
}

.signupAppBanner {
    text-align: center;
}

.signupConfirm {
    margin: auto;
    max-width: 74rem;
    border-radius: 2rem;
    background: #fff;
    padding: 1px;

    &Banner,
    &BannerRead,
    &BannerMath {
        border-radius: 2rem 2rem 0 0;
        margin-bottom: 2rem;
        background: linear-gradient(#20364B, #1D2671);
        display: flex;
        align-items: center;
        padding: 2rem;
        justify-content: space-between;

        img {
            max-width: 33%;
        }
    }

    &BannerRead {
        background: #006F81;
        min-height: 20rem;
    }
    &BannerMath {
        justify-content: flex-start;
        background: linear-gradient(#fff, #DEDEDE);
        img {
            max-width: 190px;
            margin-right: 2rem;
        }
    }

    &Content {
        padding: 2rem;
        text-align: left;
    }
}

.interestLinks {
    display: flex;
    align-items: center;
    margin: 3rem;
    padding-bottom: 4rem;
    &Item {
        padding: 2rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.3);
        border-radius: 2rem;
        margin: 0 2rem;
        text-align: center;
        flex-basis: 50%;
        display: block;
        &Img {
            max-width: 100%;
            display: block;
            margin: 0 auto 2rem auto;
            max-height: 135px;
        }
        &Logo {
            margin: auto;
            min-width: 15rem;
        }
    }
}