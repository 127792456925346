@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.goReadLocked {
    &Text {
        margin: 0 auto 40px;
        max-width: 500px;
    }
    &Footer {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 40px;
    }
    &TextLink {
        color: $body-color;
        margin-top: 20px;
        text-decoration: underline;
        &:hover {
            color: lighten($body-color, 20%)
        }
    }
}

.button {
    background: #80b814;
    border: 1px solid #80b814;
    border-radius: 9px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    padding: 6px 20px;
    text-transform: uppercase;
    &:hover {
      background: lighten(#80b814, 5%);
    }
    &Outline {
      background: #ffffff;
      color: #80b814;
      &:hover {
        background: #f2f2f2;
      }
    }
    &:disabled,
    &.disabled {
      background: #d4d4d4;
      border-color: #d4d4d4;
      color: #979797;
      pointer-events: none;
    }
    &.disabled {
      pointer-events: initial;
    }
    &FullWidth {
      width: 100%;
    }
    &Large {
      font-size: 28px;
      line-height: 42px;
    }
  }

@include breakpoint-up(sm) {
    .goReadLocked {
        background: transparent;
        border-radius: 0;
        text-align: center;
        padding: 0;
        &Title {
            font-size: 2.8rem;
        }
    }
}

.trialNoStudents {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &Input {
    width: 8rem !important;
    flex: 0 !important;
    margin-left: auto;
  }
}

@include breakpoint-up(md) {
  .trialNoStudents {
    align-items: flex-start;
  }
}