.dropdownHolder {
    position: relative;

}

.dropdownBtn {
    border: 1px solid #ccc;
    border-radius: .5rem;
    padding: .4rem 1rem;
    display: flex;
    align-items: center;
    svg {
        margin-left: .5rem;
    }
    &:disabled {
        pointer-events: none;
        opacity: .5;
    }
}
.dropdownBtnFull {
    width: 100%;
    svg {
        margin-left: auto;
    }
}
.dropdownMenu {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 10;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 160px;
    max-width: 240px;
    white-space: nowrap;
    li {
        padding: 5px 10px;
        margin: 0;
        &:not(:last-child) {
          border-bottom: 1px solid #e6e6e6;
        }
    }
    &Item {
        padding: 0;
        border: none;
        cursor: pointer;
        color: #3a3a3a;
        display: block;
        width: 100%;
        text-align: left;
        &:hover:not(:disabled) {
          color: $primary;
        }

    }
}
.dropdownMenuFull {
    width: 100%;
    button {
        display: flex;
        justify-content: space-between;
    }
}