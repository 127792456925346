@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.schoolPanel {
    align-items: center;
    background: white;
    color: $body-color;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    &:hover {
        color: $primary
    }
    &Disabled {
        pointer-events: none;
    }
    &Img {
        border-radius: 1.5rem;
        max-height: 3rem;
        max-width: 3rem;
    }
    &Text {
        margin-left: 1rem;
    }
    &Code {
        margin-left: 4rem;
        margin-bottom: 1rem;
    }
}
