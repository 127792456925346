//Display
$display-properties: (
  none: none,
  flex: flex,
  block: block,
  inline: inline,
  inline-block: inline-block
)!default;

$width-properties: (
  full: 100%,
  auto: auto
)!default;

@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);
  @include breakpoint-up($point, $grid-breakpoints) {
    @each $name, $prop in $display-properties {
      .u-display#{$infix}-#{$name} {display: #{$prop}!important}
    }

    @each $name, $prop in $width-properties {
      .u-width#{$infix}-#{$name} {width: #{$prop}!important}
    }
  }
}

//flex
.u-display-flex {
  display: flex;
}
.u-align-center {
    align-items: center;
}
.u-justify-center {
    justify-content: center;
}
.u-justify-between {
  justify-content: space-between;
}
.u-flex-center {
  display: flex;
  align-items: center;
}
.u-float-left {
  float: left;
}