@import '../../styles/variables';
@import '../../styles/mixins';

.appLayoutInterest {
    &:before {
        background: #fff !important;
    }
}
.closeRegisterBtn {
    position: absolute;
    right: 50px;
    top: 30px;
    color: #fff;
    z-index: 1;
}
.interestHeroSide {
    display: none
}
.interest {
    &Hero {
        position: relative;
        margin-top: -1rem;

        &Write {
            background: linear-gradient(#20364B, #1D2671) no-repeat left top;
        }

        &Read {
            background: linear-gradient(#006F81, #009E94) no-repeat left top;
        }
        &Container {
            display: flex;
            max-width: 1060px;
            margin: 0 auto;
            padding: 0 20px;
        }
        &Content {
            max-width: 80rem;
            margin-left: auto;
            padding: 6rem 2rem;
            color: #fff;
            margin-right: auto;
            text-align: center;
        }
        &Side {
            align-items: center;
            padding-left: 20px;
        }
        &SideImg {
            width: 500px;
            height: auto;
        }
    }

    &MainTitle {
        font-weight: bold;
        font-size: 2rem;
    }

    &Subtitle {
        font-weight: normal;
        font-size: 1.6rem;
    }

    &Write {

        &Circles,
        &Logo {
            max-width: 230px;
            display: block;
            margin: 0 auto 2rem auto;
        }

        &Logo {
            max-width: 385px;
        }

        &Limited {
            color: #FFD653;
            font-weight: bold;
            padding-top: .7rem;
            padding-bottom: .7rem;
            border-top: 1px solid #FFD653;
            border-bottom: 1px solid #FFD653;
            margin: 2rem auto 2rem auto;
            max-width: 38rem;
        }
    }

    &Read {
        &Icicles {
            min-height: 100px;
            background-size: contain;
            width: calc(100% + 4rem);
            margin-left: -2rem;
            position: relative;
            top: -20px;

            @include breakpoint-up(md) {
                min-height: 140px;
            }

            @include breakpoint-up(lg) {
                min-height: 140px;
            }


        }
    }




    &Row {
        background: #fff;
        padding: 2rem;

        &Alt {
            background: #f2f2f2;
        }

        &Read {
            padding-top: 0;
        }

        &Content {
            max-width: 500px;
            margin: auto;
            color: #696868;
            text-align: center;
        }
    }

    &Heading {
        font-size: 2.4rem;
        font-weight: bold;
    }

    &SubHeading {
        font-size: 2rem;
        font-weight: bold;
    }

    &Captcha {
        margin: 0 auto 2rem auto;
        text-align: center;
    }

    &ColElement {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        align-items: center;
    }

    label {
        margin-bottom: 5px;
    }

    &Share {
        background: #A9EAD8;
        &Read {
            background: #f2f2f2;
        }
    }

    &DoneContent {
        max-width: 60rem;
        padding: 3rem;
        text-align: center;
        margin: auto;
    }
}

@media screen and (min-width: 700px) {
    .interestHeroSide {
        display: flex
    }
}