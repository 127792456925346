@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.pack {
    display: flex;
    align-items: center;
    &Meta {
        flex-basis: 50%;
        max-width: 50%;
    }
    &Actions {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}