@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.selectedPack {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $gray-1;
    &Head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &Btn {
            width: 3rem;
            margin-top: .5rem;
            &:hover {
                color: $primary;
            }
        }
        &Price {
            font-weight: bold;
        }
    }
    &Body {
        margin-left: 3rem;
    }
    &Total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
    }
    &Meta {
        flex-basis: 60%;
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        li {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            svg {
                margin-right: .5rem;
            }
        }
        &Input {
            width: 7rem!important;
            flex: 0 0 auto!important;
            margin-bottom: 0!important;
        }
    }
}