@import '../../styles/mixins';
.container {
    max-width: 90rem;

}
@include breakpoint-up(md) {
    .contentHolder {
        align-items: flex-start;
        display: flex;
        &Left {
            align-items: flex-start;
            display: flex;
            max-width: none;
        }
    }
    .details {
        margin: 0 20px;
        min-width: 305px;
    }
}