@import '../../styles/variables';
@import '../../styles/mixins';

.dashboard {
  &Wrapper {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 1.5rem;
    border-radius: 1rem;
    max-width: 100rem;
  }
  &Main {
    margin-bottom: 1rem;
  }

}


.app {
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    
    &Link {
        background: $accent-3;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        &Img {
            width: 115px;
        }
        &Disabled {
            background: #ccc;
        }
        &Write {
            background: #6B9A11;
        }
        &Icon {
            background: #fff;
            width: 4rem;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $body-color;
            border-radius: 2rem;
        }
    }
    &BannerRead,
    &BannerWrite,
    &BannerLeavers {
        padding: 1.4rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        width: 100%;
        text-align: left;
        background: linear-gradient(#20364B, #1D2671) no-repeat left top;
        color: #fff;
        &:not(:disabled) {
            cursor: pointer;
        }
        &Main {
            padding-right: 2rem;
            line-height: 1.2;
        }
        &Img {
            max-width: 18rem;
        }
    }
    &BannerLeavers {
        background:  url('../../images/dashboard/leavers-ad.png') no-repeat right top, url('../../images/dashboard/leavers-bg.png') no-repeat left top;
        background-size:  contain, cover;
        padding-right: 150px;
        &:not(:disabled) {
            cursor: default;
        }
        &Main {
            max-width: 340px;
            position: relative;
        }
        &Btn {
            background: $primary;
            text-transform: uppercase;
            color: #fff;
            border-radius: 1rem;
            padding: 6px 15px;
            display: inline-block;
            margin-top: 10px;
            font-size: 18px;
        }
        &Img {
            position: absolute;
            width: 79px;
            height: 79px;
            top: 50%;
            margin-top: -40px;
            right: -80px;
        }
    
    }
    &BannerRead {
        background: #006F81 url('../../images/interest/snow-repeat-pattern.png');
    }
    &Extra {
        display: flex;
        flex-direction: column;
        margin-left: -.5rem;
        margin-right: -.5rem;
    }
    &Help,
    &Settings,
    &Info {
        border: 1px solid $accent-3;
        display: flex;
        align-items: center;
        border-radius: 1rem;
        border-radius: 1rem;
        flex-basis: 50%;
        flex: 1;
        padding: 1rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 1rem;
        color: $body-color;
        &:hover {
            color: $primary;
        }
    }
    &Info {
        text-align: center;
        &:hover {
            color: inherit;
        }
    }
    &Help {
        justify-content: space-between;
        &Img {
            max-width: 75px;
        }
    }
    &HelpWrite,
    &SettingsWrite {
        border-color: #6B9A11;
    }
}

@include breakpoint-up(sm) {
    .app {
        &Extra {
            flex-direction: row;
        }
        &Help,
        &Settings,
        &Info {
            margin-bottom: 0;
        }
    }
    .dashboard {
        &Wrapper {
            background: none;
            padding: 0;
        }
    }
}

@include breakpoint-up(md) {
    .dashboard {
        &Wrapper {
            flex-direction: row;
        }
        &Main {
            flex-basis: 60%;
            max-width: 60%;
            padding-right: 3rem;
        }

        &Side {
            flex-basis: 40%;
            max-width: 40%;
        }
    }
    .app {
        &BannerRead,
        &BannerWrite {
            &Main {
                max-width: 30rem;
            }
        }
    }
}




.helpTextLink {
    align-items: center;
    color: $tertiary;
    display: flex;
    margin: 5px 0;
    &:hover {
        color: lighten($tertiary, 10%);
    }
}



// promo book ad

.promoBookAd {
    display: block;
    margin-bottom: 2rem;
    width: 100%;
    &Content {
       display: block;
       width: 100%;

    }
}



.surveyAdd {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    color: $body-color;
    &Main {
        flex: 1;
    }
    &Icon {
        flex-basis: 6rem;
        max-width: 6rem;
        text-align: center;
    }
}



.pendingRequests,
.subPanel {
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    color: $body-color;
    display: block;
    width: 100%;
}
.parentAd {
    max-width: 32rem;
}

.changeYearBanner,
.wondePreApprovedBanner {
    cursor: pointer;
    margin-bottom: 2rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    border-radius: 1rem;
    padding-right: 2rem;
    background: #0097AF;
    &Main {
        flex: 1;
        color: #FFF;
        padding: 2rem; 
    }
    &Side {
        flex-basis: 25rem;
        max-width: 25rem;
        padding: 2rem;
        background: url('../../images/dashboard/change-years.png') no-repeat right top;
        background-size: contain;
    }
    &Btn {
        text-transform: uppercase;
        font-weight: bold;
        padding: .4rem 1rem;
        border-radius: 1rem;
        color: #696868;
        border: 1px solid #ccc;
        font-size: 1.2rem;
        cursor: pointer;
    }
}
.wondePreApprovedBanner {
    background: #3869FF;
}
.wondePreApprovedBannerSide {
    flex-basis: 20rem;
        max-width: 20rem;
    background: url('../../images/dashboard/wonde-logo.png') no-repeat right top;
    background-size: contain;
}
.wondeWarning {
    background: #fde6bb url('../../images/wonde-icon-colour.svg') no-repeat 12px 15px;
    background-size: 35px;
    margin-bottom: 2rem;
    padding: 1.2rem;
    padding-left: 6rem;
    border-radius: 1rem;
}
.changeYearHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    h2 { 
        color: #696868;
    }
}

.changeYearInfo {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

@include breakpoint-up(md ){
   .changeYearInfo {
    flex-direction: row;
    &Main {
        flex-basis: 50%;
        max-width: 50%;
    }
    img {
        max-width: 45rem;
    }
   }
}


.renewalBanner {
    border-radius: 2rem;
    background: #E1E1E1;
    padding: 1rem 2rem;
    margin-bottom: 1.6rem;
    color: #696868;
    cursor: pointer;
    display: block;
}
.indiaBanner {
    background-image: linear-gradient(180deg, #EDEDED 0%, #EDEDED 0%, #A4A4A4 100%);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.jkImg {
    width: 200px;
    height: auto;

}

.TagsContainer {
    margin-bottom: 1rem;
}

.Tag {
    padding: 5px;
    margin-right: 1rem;
    color: #fff;
}

.New {
    background-color: $danger;
}

.BoomReader {
    background-color: #1E2671;
}

.BoomWriter {
    background-color: $accent-3;
}
