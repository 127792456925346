@import '../../styles/variables';
@import '../../styles/mixins';

.faq {
    max-width: 60rem;
    &Title {
        cursor: pointer;
        padding: 1rem 0;
        border-bottom: 1px solid $gray-1;
        display: flex;
        align-items: center;
        svg {
            color: $gray-3;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .5rem;
        }

    }
    &Content {
        max-height: 0;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
        &Inner {
            margin-bottom: 2rem;
        }
        &Active {
            max-height: 80rem;
            transition: max-height 0.25s ease-in;
        }
    }
}