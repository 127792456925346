.auth-page {
    &__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 44rem;
        margin: auto;
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: linear-gradient($primary-light, $primary) no-repeat left top;
            background-size: cover;
            z-index: -1;
        }
    }
    &__panel {
        background: #FAFAFA;
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 85px 10px 20px 10px;
        padding: 0 20px 40px;
    }

    &__confirm-email {
        margin-bottom: 2rem;
        img {
            margin: 0 -2rem;
            position: relative;
            width: calc(100% + 4rem);
            max-width: none;
        }
    }
    &__logo {
        margin: auto;
        margin-top: -50px;
        max-width: 12rem;
    }

    &__title {
        margin: 20px 0;
        text-align: center;
        text-transform: uppercase;
    }
    &__title-light {
        margin: 20px 0;
        text-align: center;
    }
    &__logged-out {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        &__icon {
            height: 42px;
            width: 42px;
        }
        &__text {
            font-size: 16px;
            font-weight: lighter;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }
}


// Updated styles
.auth {
    &:before {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient(#1d2671, #c33764) no-repeat left top;
        background-size: cover;
        z-index: -1;
    }
    &__applogo {
        width: 46px;
        height: auto;
    }
    &__header {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
        margin-bottom: 1rem;
        padding: 1rem;
        h1 {
            font-size: 1.6rem;
        }
        &__logo {
            align-items: center;
            font-weight: bold;
            img {
                margin-right: 1rem;
                max-width: 4.2rem;
            }
        }
    }
    &__content {
        padding: 2rem;
    }
    &__panel {
        padding-top: 0;
        max-width: 74rem;
        margin: 2rem auto;
        background: #FAFAFA;
        border-radius: 2rem;
        text-align: center;
    }
    &__info-panel {
        text-align: center;
        background: #E9E9E9;
        padding: 1.5rem;
        p {
            max-width: 50rem;
            margin: auto;
        }
    }
    &__form {
        max-width: 35rem;
        margin: auto;
        padding: 2rem;
    }
    &__link {
        cursor: pointer;
        color: $primary;
        &:hover {
            color: darken($primary, 10%);
        }
    }
    &__login-wrapper {
        display: flex;
        max-width: 120rem;
        margin: auto;
        align-items: flex-start;
        flex-direction: column;

        .auth__panel-main {
            width: 100%;
        }

    }
}

.login-header {
    position: relative;
}
.login {
    &__mobile-toggle {
        display: flex;
        align-items: center;
        background: #EEEEEE;
        padding: 1rem;
        border-radius: 2rem 2rem 0 0;
        cursor: pointer;
        margin-bottom: 2rem;
        span {
            display: block;
            font-weight: bold;
            margin-left: .5rem;
        }
        svg {
            margin-left: auto;
        }
    }
    &__mobile {
        display: none;
        position: absolute;
        top: 4rem;
        width: 100%;
        z-index: 2;
        box-shadow: 0 0.2rem .3rem 0 rgba(0, 0, 0, 0.16);
        text-align: left;
        &--visible {
            display: block;
        }
        &__link {
            background: #EEEEEE;
            padding: .6rem 1rem;
            border-top: 1px solid lighten(#ccc, 5%);
            a {
                color: $body-color;
            }
            span {
                font-weight: bold;
            }
        }
    }
    &__opts {
        display: none;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 3rem 3rem 0 0; 
        margin-bottom: 2rem;  
        &__link {
            flex: 1;
            background: #EEEEEE;
            padding: 1rem;
            position: relative;
            line-height: 1.4;
            font-size: 1.8rem;
            a {
                color: lighten($body-color, 10%);
            }
            span {
                display: block;
                font-weight: bold;
                margin-left: .5rem;
            }
            &:first-of-type {
                border-radius: 3rem 3rem 0 0;
            }
    
            &--active {
                color: #fff!important;
                a {
                    color: #fff;
                }
                &:after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 100%;
                    left: calc(50% - 1rem);
                    border-left: 1rem solid transparent;
                    border-right: 1rem solid transparent;
                    border-top: 1rem solid #C33764;
                }
            }
        }
    }
}



@include breakpoint-up(sm) {
    .auth {
        &__panel {
            border-radius: 3rem;
        }
        &__header {
            h1 {
                font-size: 2rem;
            }
        }
    }

    .login {
        &__mobile-toggle,
        &__mobile {
            display: none;
        }
        &__opts {
           flex-direction: row;
           display: flex;
           &__link {
                text-align: center;
                display: block;
                span {
                    font-size: 2.2rem;
                    margin-left: 0;
                }
                &--active {
                    background: #C33764;
                }
                &:first-of-type {
                    border-radius: 3rem 0 0 0;
                    border-right: 1px solid #ccc;
                }
                &:last-of-type {
                    border-radius: 0 3rem 0 0;
                    border-left: 1px solid #ccc;
                }
           }
        }
    }
}

@include breakpoint-up(lg) {
    .auth {
        &__login-wrapper {
            flex-direction: row;

            .auth__panel-main {
                flex-grow: 1;
            }

            .auth__panel-side {
                margin-left: 3rem;
                flex-basis: 36rem;
                max-width: 36rem;
            }
        }
    }
}

.wondeLoginBtn {
    background: #3663FF;
    color: #fff;
    padding: 5px 20px;
    border-radius: 10px;
    display: block;
    margin: auto;
    margin-top: 2rem;
    text-align: center;
}