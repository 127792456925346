@import '../../styles/variables';
@import '../../styles/mixins';

.container {
    max-width: 110rem;
    display: flex;
}
.tabContainer {
    display: flex;
    flex-direction: column;
}
.newYearTabs {
    flex-direction: column-reverse
}
.tabList {
    margin-right: 40px;
    margin-top: 40px;
    min-width: 200px;
}
.yearTab {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    border-bottom: 1px solid #CCC;
    padding: 5px;
    margin-bottom: 5px;
    &Icon {
        margin-left: 5px;
    }
    &Counter {
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    &Active {
        color: #80B814;
        span {
            font-weight: bold;
        }
    }
}


.search {
    padding: 5px;
    border-radius: 15px;
    position: relative;
    display: flex;
    padding-right: 30px;
    flex-grow: 1;
}
.searchBox {
    max-width: 400px;
}
.studentAva {
    border-radius: 100px;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    flex: 0 0 auto; 
}
.inputHolder {
    position: relative;
    width: 100%;
    max-width: 400px;
}
.searchIcon {
    position: absolute;
    right: 7px;
    top: 8px;
}
.resultsWrapper {
    &List {
        position: absolute;
        top: 42px;
        left: 5px;
        max-width: 400px;
        right: 25px;
        background: #fff;
        max-height: 200px;
        overflow-y: auto;
        padding: 1.2rem 0;
        border-radius: 1rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.2);
        border: 1px solid $gray-2;
        z-index: 2;
        .searchLink {
            padding: .5rem 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: bold;
            .studentName {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 0;
                &Archived {
                    cursor: default
                }
            }
            .className {
                font-weight: normal;
                margin-left: auto;
                font-size: 12px;
                flex: 0 0 auto; 
            }
            &:hover {
                background: #f2f2f2;
            }
        }
        .searchLinkNotFound {
            padding: .5rem 1rem;
            display: flex;
            align-items: center;
        }
    }
}

.studentYearGroup {
    display: block;
}

.studentList {
    flex-grow: 1;
}

.studentItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #CCC;
    width: 100%;
    &Name {
        display: block;
        cursor: pointer;
        &:hover {
            color: #80B814
        }
    }
    &Ava {
        border-radius: 100px;
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }
    &Meta {
        display: flex;
        align-items: center;
    }
}

.dupeItem {
    border: 1px dashed red;
    padding: 20px;
    margin-top: 20px;
}

.dupeStudentItem {
    background: #FAFAFA;
    padding: 20px;
    margin-bottom: 10px;
}

.searchAndAdd {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.processing {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.moveActions {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    background: #efefef;
}

.classLink {
    cursor: pointer;
    color:#80B814;
}

.studentItemsWrapper {
    height: 550px;
    overflow-y: auto;
    padding: 5px 10px;
}