@import '../../../../styles/variables';

.setupInfo {
   // max-width: 90rem;
    &Header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 3rem;
        justify-content: space-between;
        &Main {
            max-width: 70rem;
            padding-right: 3rem;
        }
    }

    &Time {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 2.3rem;
        background: #FAFAFA;
        margin-left: auto;
        min-width: 300px;
        padding: 3rem 2rem;
        border-radius: 1rem;
    }
}

.setupList {
    &Item {
        margin-bottom: 2rem;
        display: flex;
        background: #FAFAFA;
        padding: 2rem;
        border-radius: 1rem;
    }

    &No {
        margin-right: 1rem;
        width: 3rem;
        height: 3rem;
        background: $accent-3;
        color: #fff;
        text-align: center;
        line-height: 3rem;
        border-radius: 1.5rem;
    }

    &Row {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem
    }
}

.setupDownloadBtn {
    border-radius: 1rem;
    padding: .1rem 1.4rem;
    font-size: 1.4rem;
    border: 1px solid #696868;
    text-transform: uppercase;
    color: #696868;
    display: inline-block;
    font-weight: bold;
    margin: .5rem 0 0 2.5rem;
}

//create school
.schoolSetup {
    //max-width: 800px;

    &Form {
        display: grid;
        grid-template-columns: 5fr 3fr;
        margin-bottom: 3rem;
        gap: 3rem
    }

}

.setupLogo {
    align-items: flex-start;
    p {
        display: block;
        margin-bottom: 1rem;
    }
}