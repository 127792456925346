@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.packList {
    list-style: none;
}
.pack {
    padding: 1.6rem;
    border-radius: 1rem;
    background: lighten($gray-1, 10%);
    border: 1px solid $gray-1;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 1rem;
    &Head {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
    }

    &Meta {
        flex-basis: 60%;
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        li {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            svg {
                margin-right: .5rem;
            }
        }
        &Input {
            width: 7rem!important;
            flex: 0 0 auto!important;
            margin-bottom: 0!important;
        }
    }
    &Add {
        &Btn {
            margin-left: .5rem;
            &:hover {
                color: $primary
            }
        }
    }
}

