@import '../../styles/variables';
@import '../../styles/mixins';

.container {
    max-width: 110rem;
}

@include breakpoint-up(md) {
    .container {
        &Side {
            max-width: 25rem!important;
            flex-basis: 25rem!important;
            margin-right: 3rem;
        }
    }
}

.archiveBanner {
    background: #F3F3F3;
    align-items: center;
    display: flex;
    color: #4a4a4a;
    padding: 20px 40px 20px 20px;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 10px;
}


.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    &Left {
        display: flex;
        align-items: center;
    }
}

.classDownloadHolder {
    background: #C6CBB5;
    padding: 1rem;
    margin: 1rem 0 1.5rem 0;
    a {
        color: $body-color;
    }
}

.confirmArchive {
    padding: 2rem;
    background: #efefef;
    margin-bottom: 2rem;
}

.shadowPanel {
    background: #FFF;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0px 2px rgba(0,0,0,0.1), 
    0 2px 2px rgba(0,0,0,0.1), 
    0 4px 4px rgba(0,0,0,0.1), 
    0 8px 8px rgba(0,0,0,0.1),
}

.selectTeachersHeader {
    position: sticky;
    top: 0;
    padding-top: 1rem;
    background: #fff;
    z-index: 1;
    display: flex;
}
