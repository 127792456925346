@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.selectStudents {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &Title {
        margin: 0 0 1rem 0;
        font-weight: bold;
    }
    &Input {
        width: 8rem!important;
        flex: 0!important;
        margin-left: auto;
    }
}

@include breakpoint-up(md) {
    .selectStudents {
        align-items: flex-start;
    }
}