@import '../../../../styles/variables';

.classAssignPack {
    &Button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
        &Info {
            display: flex;
            align-items: center;
        }
        &:focus {
            outline: none;
        }
        &:hover svg {
            color: $primary
        }
    }
}