@import '../../styles/variables';
@import '../../styles/mixins';

.pageHeader {
    min-height: 8rem;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    justify-content: space-between;
    &Content {
        color: #fff;
        &Title {
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }
        &Back {
            border: 1px solid $gray-1!important;
            border-radius: 2.2rem;
            color: #fff;
            display: flex;
            height: 4.4rem;
            width: 4.4rem;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            &:hover {
                background: rgba(0,0,0,0.1);
            }
        }
    }
    &Centered {
        justify-content: center;
    }
}

@include breakpoint-up(sm) {
    .pageHeader {
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid lighten($gray-1, 10%);
        margin: -2rem -3rem 2rem -3rem ;
        width: calc(100% + 6rem);
        padding: 1rem 3rem;
        &Content {
            color: $body-color;
            &Back {
                color: $body-color;
                &:hover {
                    background: $gray-1;
                }
            }
        }
    }
}

@media print {
    .pageHeader {
        display: none;
    }
}