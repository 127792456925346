
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    min-height: 100%;
}

body {
    font-family: $body-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: left;
    color: $body-color;
    position: relative;
    @include breakpoint-up(md) {
        font-size: $base-desktop-font-size;
    }
}

.app {
    &__layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        position: relative;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: linear-gradient(to bottom, $primary-dark 0%, $primary calc(100% - 11rem)) no-repeat left top;
            background-size: cover;
            z-index: -1;
        }

    }
    &__content {
        padding: 7rem 2rem 13rem 2rem;
    }
    &__content--profile-check {
        background: white;
        // padding: 7rem 2rem 2rem 2rem;
        padding: 20px;
        min-height: 100vh;
    }
}

.impersonating, .noSocket, .socketInfo {
    background: blue;
    color: white;
    font-size: 1rem;
    left: 0;
    opacity: 0.5;
    padding: .5rem;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 2000;
}

.noSocket {
    background: red;
    opacity: 1;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
    font-size: 1.2rem;
    cursor: pointer;
    border: 0;
}

.socketInfo {
    background: #FFF;
    top: 0;
    bottom: 0;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a4a4a;
    font-size: 1.4rem;
    gap: 10px;
    flex-direction: column;
    padding: 40px
}

.socketPara {
    max-width: 600px;
}

@include breakpoint-up(sm) {
    .app {
        &__layout {
            background: #fff;
        }
        &__content {
            padding: 2rem 3rem 2rem 13rem;
            //padding: 0;
        }
        &__content--profile-check {
            // padding: 0;
        }
    }

}


@media print {
    .app__layout {
        display: block;
        min-height: none;
        overflow: hidden;
        &:before {
            display: none;
            position: static;
        }
    }
    .app__content {
        padding-top: 0;
    }
}

.separator {
    height: 1px;
    background: #E1E1E1;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: none;
}