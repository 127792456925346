@import '../../../../styles/variables';

.trial {
    margin-bottom: 2rem;
    border-radius: 1rem;
    padding: 1.4rem;
    display: flex;
    justify-content: space-between;
    background: lighten($gray-1, 12%);
    &Main {
        flex: 1;
    }
}

.labelActive,
.labelTrial,
.labelCancelled {
    display: inline-block;
    padding: 2px 10px;
    color: #fff;
    border-radius: 3px;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 1rem;
    background: lighten($primary-dark, 8%);
    vertical-align: middle;
}
.labelTrial {
    background: $tertiary;
}

.labelCancelled {
    background: $accent-3;
}
.subInfo {
    display: flex;
    align-items: flex-start;
    &Main {
        flex: 1;
    }
    &Side {
        flex-basis: 28rem;
        background: lighten($gray-1, 12%);
        border-radius: 1rem;
        padding: 2rem;
    }
}
.subDates {
    margin-bottom: 2rem;
    li {
        display: flex;
  
    }
    &Label {
        flex-basis: 14rem;
    }
}
.cardInfo {
    li {
        margin-bottom: 1rem;
        display: flex;
        p {
            flex-basis: 14rem;
            flex-shrink: 0;
        }
    }
}

.billingHistory {
    max-width: 60rem;
    &Item {
        display: flex;
        align-items: center;
        padding-bottom: .5rem;
        border-bottom: 1px solid #ccc;
        margin-bottom: .5rem;
    }
    &Date {
        flex-basis: 14rem;
    }
    &Body {
        flex: 1;
    }
    &Side {
        margin-left: auto;
    }
}

.cancel {
    max-width: 90rem;
    &Header {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &Panel {
        padding: 1.6rem;
        border-radius: 1rem;
        background: lighten($gray-1, 10%);
        max-width: 40rem;
    }
}

.students {
    font-size: 2rem;
    display: flex;
    margin: 2rem 0 0 0;
    align-items: center;

    &Title {
        margin: 0 2rem 0 0;
    }

    &Total {
        display: flex;
        align-items: center;
    }

    &Input {
        max-width: 7rem;
        margin: 0 1rem 0 0 !important;
    }
}

.processing {
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 2;

    &Loader {
        height: 10rem;
        width: 10rem;
        animation: spin 2s linear infinite;
    }

    &Text {
        font-size: 2.4rem;
    }
}

.receiptOpts {
    &Main {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
    }

    &Bullet {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 1rem;
        border-radius: 1rem;
        border: 1px solid #ccc;

        &Active {
            background: $primary;
            border-color: $primary;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}