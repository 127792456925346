@import '../../../styles/variables';

.optionsContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.2);
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel {
    background: #FFF;
    display: flex;
    padding: 20px;
}
.panelLeft {
    flex-grow: 1;
    min-width: 300px;
}
.panelRight {
    border-left: 1px solid #CCC;
    margin-left: 20px;
    padding-left: 20px;
}

.moveOptLink {
    cursor: pointer;
    padding: .5rem 0;
    &:hover {
        color: $primary;
    }
}