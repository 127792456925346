.logo {
    border-radius: 3rem;
    max-height: 6rem;
    max-width: 6rem;
}
.school {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.active {
    background: #f3f3f3!important;
}