@import '../../../../styles/variables';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
    &Label {
        display: inline-block;
        padding: 3px 10px;
        color: #fff;
        border-radius: 5px;
        background: $tertiary;
    }
}