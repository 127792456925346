.container {
    display: block;
    text-align: left;
    padding: 40px;
    position: relative;
}

.option {
    border: 1px solid #CCC;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.optionSelected {
    background: #eae9e9;
}

.close {
    position: absolute;
    top: -14px;
    left: -14px;
    height: 38px;
    width: 38px;
    background: #FFF;
    border-radius: 100px;
    padding: 5px;
    border: 1px solid #000;
    cursor: pointer;
    &:hover {
        background: #eae9e9;
    }
}

.countrySelectLabel {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    &Alt {
        justify-content: left;
        margin-bottom: 20px;
    }
}

.countrySelectPara {
    display: flex;
    align-items: center;
}

.countrySelectImg {
    width: 30px;
    height: auto;
    margin-left:10px;
}

.countrySelectBtn {
    font-weight: bold;
    text-decoration: underline;
    margin-left: 10px;
}

.countrySelectImgAlt {
    width: 40px;
    height: auto;
    margin-right: 10px;
}

.headerFlag {
    border: 1px solid #CCC;
    padding: 5px;
    display: block;
}

.headerFlagImg {
    width: 30px;
    height: auto;
}