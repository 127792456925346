@import '../../styles/variables';
@import '../../styles/mixins';

.onboarding {
    display: grid;
    grid-template-columns: 6fr 3fr;
    gap: 3rem;
    max-width: 840px;

    &Banner {
        text-align: center;
        border-radius: 2rem;
        border: 1px solid #E1E1E1;
        padding: 2rem;

        img {
            margin: 0 auto 2rem auto;
            max-width: 80%;
        }
    }
    &BannerMath {
        img {
        margin: 0 auto 2rem auto;
            max-width: 80%;
        }
    }

    &Join {
        display: block;
        max-width: 840px;
        form {
            max-width: 50rem;
        }
    }
}

.schoolLinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 3rem 0 2rem 0;

    &Item {
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.22);
        padding: 2rem;
        border-radius: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        border: none;
        font-size: 2.2rem;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.2s ease-in;

        &:hover {
            box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.4);
        }

        img {
            max-width: 90%;
            max-height: 100px;
            margin: 2rem auto 0 auto;
            align-self: center;
            justify-self: center;
        }
    }
}


// join school
.joinSchool {
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 0.1rem .5rem 0 rgba(0, 0, 0, 0.55);
    background: #FAFBFB;
    margin-top: 2rem;

    &Error {
        color: red
    }
}

.schoolHeader,
.schoolAccessHeader {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    background: #FAFAFA;
    padding: 1.5rem;
    border-radius: 1rem;

    &Img {
        flex-basis: 6rem;
        max-width: 6rem;
        margin-right: 2rem;
    }
}

.schoolAccessHeader {
    &Main {
        flex: 1;
        padding-right: 2rem;
    }
    &Side {
        flex-basis: 15rem;
        max-width: 15rem;
        text-align: center;
        img {
            max-width: 6rem;
            display: block;
            margin: auto;
            margin-bottom: 1rem;
        }
    }
}

.btnLink {
    color: #fff;
    background: $primary;
    padding: .5rem 2rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: bold;
}

//Wonde
.wondeMain {
   padding-bottom: 10rem;
}
.wondeFooter {
    background: #F7F7F7;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 100px;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 140px;
}

.wondePermissions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.staffToImport {
    position: sticky;
    top: 0;
    overflow: auto;
    max-height: calc(100vh - 300px);
}

.classTypeSelect {
    width: 14rem;
}