
.navBar {
    margin-bottom: 20px;
    display: flex;
}
.navLink {
    box-shadow: 0px 3px 5px rgba(0,0,0,0.3);
    margin-right: 10px;
    padding: 10px 20px;
    color: #4a4a4a;
    border-radius: 10px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    &Active {
        background-color: #d8d8d8;
    }
}