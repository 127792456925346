.acceptInvite {

    padding: 3rem;
    text-align: left;

    &Header {
        display: flex;
        margin: 2rem 0;
        align-items: center;

        &Img {
            flex-basis: 6rem;
            max-width: 6rem;
            margin-right: 2rem;
        }

        &Main {
            flex: 1;
        }
    }

    &Form {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 2rem;
    }



    &Col {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    &Locked {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
}

.authPanelNarrow {
    width: 45rem;
}
.inviteExpired {
    margin: auto;
    text-align: center;
    img {
        margin: auto;
    }
}