.modal {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    &Blanker {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        height: 100%;
        justify-content: center;
        overflow: auto;
        width: 100%;
    }

    &Content {
        background: #fff;
        border-radius: 1rem;
        padding: 2rem;
        overflow: auto;
        position: relative;

        margin: 20px;
        max-height: calc(100vh - 40px);
        max-width: 500px;
        overflow: initial;
        position: relative;
        width: 100%;
    }
}