.close {
    position: absolute;
    top: 20px;
    border: 0;
    right:0;
    padding: 5px;
    display: block;
    height: 44px;
    cursor: pointer;
}

.studentHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CCC;
    padding-bottom: 10px;
    margin-bottom: 10px;
    max-width: 960px;
    position: relative;
}

.studentEdit {
    max-width: 960px;
    position: relative;
}

.yearTab {
    background-color: #F1f1f1;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 10px;
}

.studentAva {
    width: 75px;
    height: auto;
}

.heading {
    font-weight: bold;
    margin: 0;
    &Upn {
        font-weight: 100;
        font-size: 18px;
    }
}

.editBtn {
    margin: 5px 0 0 10px; 
    cursor: pointer;
}

.studentLayout {
    display: flex;
    max-width: 960px;
    align-items: flex-start;
}

.studentClasses {
    margin-right: 40px;
    flex-grow: 1;
}

.yearSelect {
    width: initial;
    display: block;
    margin-top: 20px;
}
.classLink {
    border-bottom: 1px solid #CCC;
    padding: 5px 0;
    color: #4a4a4a;
    display: flex;
}

.formFields {
    display: flex;
    align-items: center;
}

.field {
    margin-right: 20px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #CCC;
}

.loginCode {
    display: flex;
    align-items: center;
}

.yearLoad {
    height: 41px;
    width: auto;
}

.classLinkInner {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-right: 10px;
}

.removeFromClass {
    cursor: pointer;
}