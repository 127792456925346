@import '../../styles/mixins';


@include breakpoint-up(md) {
    .contentHolder {
        align-items: flex-start;
        display: flex;
        &Left {
            flex: 1;
            max-width: 310px;
            margin-right: 2rem;
        }
    }
}

@include breakpoint-up(lg) {
    .contentHolder {
        &Left {
            display: flex;
            flex: initial;
            max-width: none;
            
        }
    }
    .form {
        margin: 0 20px;
        min-width: 305px;
    }
}