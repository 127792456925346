@import '../../styles/variables';
@import '../../styles/mixins';

.header,
.headerNoSchool {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    position: absolute;
    top: 0;
    &LogoWrapper {
        width: 5rem;
        height: 5rem;
        border-radius: 2.5rem;
        background: #fff;
        margin-top: .6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &Logo {
        width: 4rem;
    }
    &Logout {
        border: none;
        color: #FFFFFF;
        cursor: pointer;
        padding: 0;
        &:hover {
            color: #D4D4D4;
        }
        &Icon {
            height: 32px;
            width: 32px;
        }
    }
}


@include breakpoint-up(sm) {
    .header {
        display: none;
    }
    .headerNoSchool {
        left: 0;
        height: 100%;
        width: 10rem;
        background: linear-gradient($primary-dark, $primary) no-repeat left top;
        color: #fff;
        padding: 1rem;
        justify-content: space-between;
        flex-direction: column;
        position: fixed;
    }
}


@media print {
    .header {
        display: none;
    }
}