@import '../../../../styles/mixins';
@import '../../../../styles/variables';


.staffItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
    border-bottom: 1px solid #E1E1E1;

    &Main {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
    }

    &Actions {
        display: flex;
        align-items: center;

        &Item {
            margin: 0 .5rem;
        }
    }

    .select {
        padding: .3rem 1rem;
        padding-right: 25px;
        width: 11rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
        font-size: 14px;
    }

    .switchAdminToggle:checked+label::after {
        left: calc(100% - 1px);
        background: $primary;
    }

    .switchAdminToggle:checked+label {
        background: #fff !important;

        span {
            margin-left: 5px;
        }
    }

    &Header {
        .staffItemMain {
        font-size: 1.6rem;
        }
    }

    .switchAdminLabel {
        width: 80px;
        text-indent: 0;
        background: #fff;
        border: 1px solid #979797;
        font-size: 14px;
        line-height: 26px;
        &::after {
            background: #333;
            top: 1px;
            left: 2px;
        }

        span {
            margin-left: 28px;
        }
    }
}

.removeStaffBtn {
    display: flex;
    align-items: center;
}

.addStaffEmails {
    max-width: 80rem;
    display: flex;
    align-items: flex-start;

    textarea {
        margin-right: 1rem;
    }
}