@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.uploadStart {
    max-width: 100rem;

    &Header {
        max-width: 70rem;
    }

    &Info {

        align-items: flex-start;

        @include breakpoint-up(md) {
            display: grid;
            grid-template-columns: 5fr 4fr;
            gap: 2rem;
        }
    }

    &YearGroup {
        background: #EBEBEB;
        padding: 1rem;
        margin: 2rem 0;
        &Extra {
            padding: 0;
            background: none;
            td {
                padding: 4px 0;
                border-bottom: 1px solid #ccc;
            }
        }

        @include breakpoint-up(md) {
            margin: 0;
        }

        table {
            border-collapse: collapse;
            table-layout: fixed;
            line-height: 1.2;

            th {
                font-weight: normal;
                text-align: left;
            }

            th:first-of-type {
                width: 15rem;
                padding-right: 1rem;
            }

            th:nth-of-type(2) {
                width: 8rem;
            }

        }
    }
}

@include breakpoint-up(lg) {
    .uploadStart {
        display: grid;
        grid-template-columns: 5fr 3fr;
        gap: 2rem;
        align-items: flex-start;
    }
}
// @include breakpoint-up(xl) {
//     .uploadStart {
//         grid-template-columns: 700px auto;
       
//     }
// }

.panel {
    background: #FAFAFA;
    border-radius: 1rem;
    padding: 2rem;
}
.buttonContainer {
    display: inline-block;
}
.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &+label {
        background: #80b814;
        border-radius: 9px;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        padding: 6px 20px;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background: lighten(#80b814, 5%);
        }
    }

    &:focus+label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.buttonIcon {
    margin-right: .5rem;
    position: relative;
    top: .3rem;
}

//columns
.columnsPreview {
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 3rem;
    th, td {
        padding: 5px;
        border: 1px solid #DEDEDE;
        text-align: left;
        font-weight: normal;
    }
    th {
        background: #F8F7F8;
    }
}


.previewErrors {
    &Header {
        display: flex;
        margin-bottom: 2rem;
        &Main {
            flex-basis: 70%;
            max-width: 70%;
        }
        &Side {
            text-align: right;
        }
    }
    &List {
        margin: 2rem 0;
        li {
            padding: 4px 0;
            border-bottom: 1px solid #DEDEDE;
        }
    }
}
@include breakpoint-up(md) {
    .previewErrors {
        display: grid;
        grid-template-columns: 5fr 3fr;
        gap: 2rem
    }
}

.warningPanel {
    background: #ff0000;
    color: #FFF;
    display: flex;
    padding: 15px;
    margin: 20px 0;
    gap: 10px;
}