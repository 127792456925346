@import '../../../../styles/mixins';

.inputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
    z-index: -1;
    & + label {
        background: #80b814;
        border-radius: 9px;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        padding: 6px 20px;
        text-align: center;
        text-transform: uppercase;
        &:hover {
            background: lighten(#80b814, 5%);
        }
    }
    
    &:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.dlButton {
    color: #6b6b6b;
    padding: 5px 40px 5px 0;
    font-weight: bold;
    position: relative;
}

.dlIcon {
    position: absolute;
    right: 10px;
    top: 2px;
}

.typeList {
    margin: 0 0 20px 0;
    list-style-position: inside;
    list-style-type: disc;
}

.img {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

.buttonIcon {
    margin-right: .5rem;
    position: relative;
    top: .3rem;
}

.warning {
    padding: 1rem 2rem;
    background: red;
    color: #fff;
    margin-bottom: 2rem;
    display: flex;
    svg {
        margin-right: 1rem;
    }
}

.importTable {
    margin: 2rem 0;
    border-collapse: collapse;
    th {
        padding: 3px 6px 3px 3px;
    }
    td {
        padding: 3px;
        border-bottom: 1px  solid #ccc;
    }
}

.previewErrors {
    &Header {
        display: flex;
        margin-bottom: 2rem;

        &Main {
            flex-basis: 70%;
            max-width: 70%;
        }

        &Side {
            text-align: right;
        }
    }

    &List {
        margin: 2rem 0;

        li {
            padding: 4px 0;
            border-bottom: 1px solid #DEDEDE;
        }
    }
}

@include breakpoint-up(md) {
    .previewErrors {
        display: grid;
        grid-template-columns: 5fr 3fr;
        gap: 2rem
    }
}