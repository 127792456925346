@import '../../styles/variables';
@import '../../styles/mixins';

.moduleTour {
    &SlideButton {
        border: none;
        cursor: pointer;
        padding: 0;
        &Hidden {
            visibility: hidden;
        }
        &:active,
        &:focus,
        &:visited  {
            border: none;
            outline: none;
        }
        &:hover {
            color: lighten($gray-3, 20%);
        }
    }
    &Contents {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    &SlideContainer {
        margin: 0 15px;
        width: 640px;
        &Outer {
            width: 100%;
            padding-top: 50%; /* defines aspect ratio */
            position: relative;
        }
        &Inner {
            // background-color: $gray-2;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    &SlideVideo {
        height: 100%;
    }
    &SlideText {
        font-size: 18px;
        margin-bottom: 10px;
    }
    &SlideLink {
        align-items: center;
        display: flex;
        justify-content: center;
        &Icon {
            margin-left: 5px;
        }
    }
    &SlideImage {
        max-height: 300px;
        margin: 0 auto;
    }
    &Progress {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        &Marker {
            border: 1px solid lighten($gray-3, 20%);
            border-radius: 8px;
            display: flex;
            height: 16px;
            margin: 0 3px;
            transition: all 0.2s;
            width: 16px;
            &Current {
                background-color: $gray-2;
            }
        }
    }
}

@include breakpoint-up(sm) {
    .moduleTour {
        &SlideLink {
            font-size: 20px;
        }
    }
}