@import '../../styles/variables';
@import '../../styles/mixins';

.setupPrompt {
    align-items: center;
    color: $body-color;
    display: flex;
    line-height: normal;
    margin-bottom: 2rem;
    &Text {
        margin: 0 1rem;
    }
    &Title {
        align-items: center;
        display: flex;
        font-size: 18px;
    }
    &Arrow {
        margin-left: 2rem;
    }
    &:hover {
        color: lighten($body-color, 20%);
    }
}