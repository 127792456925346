@import '../../styles/variables';
@import '../../styles/mixins';


.syncMain {
    padding-bottom: 10rem;
}

.syncFooter {
    background: #F7F7F7;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 100px;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 140px;
}

.upnMatch,
.suggestedMatch,
.manualMatch {
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: 1rem;
    &Boom,
    &Wonde {
        display: grid;
        padding: 3px .5rem;
        grid-template-columns: 150px 1fr 50px;
        gap: 1rem;
    }
    &Wonde {
        grid-template-columns:  1fr 50px;
    }
}

.altRow {
    &Boom {
        background: #E9EFD4;
    }
    &:nth-child(odd) &Boom {
        background: #F3F8E4;
    }
   &Wonde {
       background: #85DDF5;
   }
   &:nth-child(odd) &Wonde {
       background: #B6EAF8;
   }
}


.suggestedMatch {
    grid-template-columns: 3fr 4fr;
    &Boom {
        grid-template-columns: 50px 1fr 50px;
    }
    &Wonde {
        grid-template-columns: 150px 1fr 50px;
    }
}
.manualMatch {
    grid-template-columns: 2fr 4fr;
    &Boom,
    &Wonde {
        grid-template-columns: 1fr 60px;
    }
}


.yearCol {
    text-align: right;
}


.staffMatch {
   border-bottom: 1px solid #ccc;
   padding: .5rem 0;
   display: grid;
   grid-template-columns: 1fr 100px;
   &Main {
    display: grid;
    margin-right: 2rem;
    flex: 1;
    max-width: 90rem;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  }
}

.resultsWrapper {
    &List {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #fff;
        max-height: 200px;
        overflow-y: auto;
        padding: 1.2rem;
        border-radius: 1rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.2);
        border: 1px solid $gray-2;
        z-index: 1;
        li {
            padding: .5rem 0;
            cursor: pointer;
        }
    }
}

.searchStudent {
    border: none;
    width: 100%;
    background: none;
}

