@import '../../../styles/variables';
@import '../../../styles/mixins';

.invoice {
    &Address {
        font-style: normal;
        line-height: 1.3;
        margin-bottom: 2rem;
    }

}

.financeOfficer {
    background: lighten($gray-1, 10%);
    padding: 1rem;
    &Field {
        align-items: center;
        display: flex;
        margin-bottom: .5rem;
        &Icon {
            margin-right: 1rem;
        }
    }
    &EditLink {
        align-items: center;
        color: $dark-green;
        display: flex;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
        &Icon {
            margin-right: .5rem;
        }
    }
}

.hiddenCheckbox {
    display: none;
}