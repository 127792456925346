.nav {
    display: none;
    background: linear-gradient(#c33764, #1d2671) no-repeat left top;
    color: #fff;
    bottom: 0;
    height: 8.5rem;
    position: fixed;
    width: 100%;
    text-align: center;
    padding: 0 1rem 1rem 1rem;
    font-size: 1.2rem;
    z-index: 10;
    &User {
        display: none;
        a {
            color: #fff;
            display: block;
            overflow-wrap: break-word;
            &:hover {
                color: #C2D582;
            }
        }
        img {
            margin: 0 auto .5rem auto;
            width: 5rem;
            height: auto;
            border-radius: 5rem;
        }
        &Name {
            text-transform: capitalize;
            margin-bottom: 1rem;
        }
    }
    &Exit {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: none;
    }
    &Brand {
        display: none;
        img {
            width: 5rem;
            margin: 0 auto 1.5rem auto;
        }
    }
    &SchoolCode {
        display: none;
        line-height: normal;
        margin: 1.5rem auto;
    }
    &Logout {
        padding: 0;
        border: none;
        color: #fff;
        margin-bottom: 1rem;
        cursor: pointer;
    }
    &Menu {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &Link {
            display: flex;
            color: #C33764;
            align-items: center;
            flex-direction: column;
            margin: 0 1.5rem;
            &Icon {
                padding: 1.2rem 1rem .8rem 1rem;
                border-radius: 0 0 1rem 1rem;
                margin-bottom: .2rem;
            }
        }
    }
}

.mobNav {
    display: flex;
    background: linear-gradient(#c33764, #1d2671) no-repeat left top;
    color: #fff;
    bottom: 0;
    height: 6.5rem;
    position: fixed;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    z-index: 10;
    align-items: center;
    justify-content: space-between;
}

.mobNavGrid {
    color: #c33764;
    background: #FFF;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.mobNavMenu {
    display: flex;
    align-items: center;
    font-weight: bold;
}


.sideMenu {
    position: fixed;
    left: 20px;
    background: #FFF;
    top: 20px;
    right: 20px;
    bottom: 85px;
    padding: 20px;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    z-index: 200;
    border-radius: 20px;
}
.sideOverlay {
    position: fixed;
    top: 0;
    bottom: 6.5rem;
    left: 0rem;
    right: 0;
    background: rgba(0,0,0,0.1);
    z-index: 300;
}

.closeBtn {
    cursor: pointer;
    margin-top: auto;
}


@media screen and (min-width: 36em){

    .closeBtn {
        display: none;
    }
    .sideMenu {
        left: 10rem;
        top: 0;
        bottom: 0;
        right: initial;
        border-radius: initial;
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.16);
    }
    .sideOverlay {
        bottom: 0;
    }
    
    .mobNav {
        display: none;
    }
    .nav {
        left: 0;
        display: block;
        height: 100%;
        width: 10rem;
        background: linear-gradient(#C33764, #1D2671) no-repeat left top;
        color: #fff;
        padding: 1rem;
        &User,
        &Brand,
        &Exit {
            display: block;
            li {
                list-style: none;
                line-height: 16px;
            }
        }
        &SchoolCode {
            display: block;
        }
        &Menu {
            flex-direction: column;
            padding: 0;
            &Link {
                color: #C33764;
                background-color: #FFF;
                margin: 1rem 0;
                padding: 10px;
                border-radius: 100px;
                &Icon {
                    padding: 0;
                    margin: 0;
                }
                &Active,
                &:hover {
                    color: #C33764;
                }
            }
            &Btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &Text {
                    margin-top: 5px;
                }
            }
        }
    }
}


@media print {
    .nav {
        display: none;
    }
}

.profileLink {
    display: flex;
    align-items: center;
    color: #696868;
    margin: 20px 0;
}

.profileImg {
    border-radius: 500px;
    width: 50px;
    height: auto;
    margin-right: 10px;
}
.profileManage {
    font-weight: normal;
    display: block;
}
.profileName {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}
.profileCode {
    font-size: 10px;
}
.sideLinks {
    min-width: 300px;
    text-align: left;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.subHeading {
    color: #696868;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    .appIcon {
        background: #696868;
        border-radius: 100px;
        display: block;
        width: 24px;
        height: 24px;
        padding: 2px 4px;
        margin-right: 10px;
    }
    &Active {
        color: #C33764;
        .appIcon {
            background: #C33764;
        }
    }
    &:hover {
        color: darken(#696868, 10%)
    }
    
}

.subSet, .endSet {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}
.subLink, .appLink, .endLink {
    color: #696868;
    padding: 5px 0 5px 34px;
    display: flex;
    align-items: center;
    &:hover {
        color: #CCCCCC;
    }
}
.subLink {
    position: relative;
    .subLinkArrow {
        position: absolute;
        left: 0;
        color: #C33764;
        top: 8px;
        display: none;
    }
}
.subLinkActive {
    color: #C33764;
    font-weight: bold;
    .subLinkArrow {
        display: block;
    }
}

.appLetter {
    font-size: 14px;
    border: 1px solid #9F9F9F;
    border-radius: 100px;
    color: #9F9F9F;
    width: 24px;
    height: 24px;
    display: block;
    text-align: center;
    line-height: 22px;
    margin-right: 10px;
    font-weight: bold;
}

.appLink {
    text-transform: uppercase;
    border-top: 1px solid #F2F2F2;
    padding: 10px 0;
}

.endSet {
    border-top: 1px solid #f2f2f2;
    padding-top: 20px;
}

.navMenuAppText {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.endLinkIcon {
    position: absolute;
    left: 20px;
}