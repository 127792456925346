@import '../../styles/variables';
@import '../../styles/mixins';

.listItem {
    align-items: center;
    border-bottom: 1px #E1E1E1 solid;
    display: flex;
    font-size: 1.4rem;
    justify-content: space-between;
    padding: 1rem 0;
    &Left {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    &MainTextLink,
    &RightButton {
        cursor: pointer;
        &:hover {
            color: #979797;
        }
    }
    &RightButton {
        margin: .5rem 0 0 1rem;
        color: $gray-2;
        width: 2rem;
    }
    &Avatar {
        border-radius: 1rem;
        margin-right: 0.5rem;
        width: 2rem;
    }
    &Right {
        display: flex;
        align-items: center;
    }
}
.short {
    padding: .5rem 0;
}

.listItemWithCard {
    margin: 0 -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

@include breakpoint-up(sm) {
    .listItem {
        font-size: 1.6rem;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }
}
