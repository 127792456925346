@import '../../../../styles/variables';
@import '../../../../styles/mixins';


.container {
    max-width: 90rem;
}

@include breakpoint-up(md) {
    .container {
        &Sidebar {
            max-width: 30rem!important;
            flex-basis: 30rem!important;
        }
    }
}

.classEditForm {
    padding: 2rem;
    border-radius: 1rem;
    background: #FAFAFA;
}

