
.basic-form {
	&__group {
        align-items: flex-start;
        display: flex;
		margin-bottom: 1rem;
		flex-direction: column;
	}
	&__group-row {
		flex-direction: row;
		align-items: center;
	}
    &__text-label {
		margin-bottom: .2rem;
    }
	&__text-box,
    &__text-select,
    &__text-area {
		flex: 1;
		width: 100%;
		border: 1px solid $gray-2;
		color: #4A4A4A;
		font-size: 1.6rem;
		line-height: 1.2;
		padding: .8rem 1rem;
        background: #fff;
        border-radius: 1rem;
		resize: vertical;
		margin-bottom: .5rem;
		transition: border-color .2s ease-in;
		&:focus {
			box-shadow: none;
			outline: none;
			border-color:$primary;
        }
        &:disabled {
            color: lighten(#979797, 20%);
        }
	}
    &__text-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #fff url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' fill='%23999999' viewBox='0 0 32 32'%3e%3cpath d='M4.516,6.742L16,18.227L27.484,6.742L31,10.257L16,25.258L1,10.257L4.516,6.742z'/%3e%3c/svg%3e") no-repeat calc(100% - 1rem) 50%;
		background-size: 1.4rem;
		padding-right: 3rem;
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #ccc;
		}
		&--inline {
			width: auto;
		}
    }
	&__group--check {
		position: relative;
		flex-direction: row;
	}
	&__check-label {
		padding-left: 2.6rem;
	}
	&__check-box {
		position: absolute;
		opacity: 0;
		z-index: 10;
		~ label:before {
			position: absolute;
			left: 0;
			display: block;
			width: 2rem;
			height: 2rem;
			text-align: center;
			line-height: 2rem;
			pointer-events: none;
			content: "";
			user-select: none;
			background: #fff;
			border: 1px solid darken($gray-1, 20%);
			top: 1rem;
			border-radius: 2px;
			transform: translateY(-50%);
			cursor: pointer;
		}
		&:checked ~ label:before {
			content: "";
			color: #fff;
			background: $primary;
			border-color: $primary;
		}
		&:checked ~ label:after {
			content: '';
			position: absolute;
			top: .5rem;
			left: .4rem;
			width: 1.2rem;
			height: .7rem;
			border: 2px solid #fff;
			border-width: 0 0 2px 2px;
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
    &__hint {
        color: red;
        font-size: 1.2rem;
        margin: -1rem 0 1rem;
	}
    &__errors,
    &__success {
		align-items: center;
		background: lighten(red, 50%);
		border: 1px solid red;
		color: red;
		display: flex;
		font-size: 1.6rem;
		margin: 2rem 0;
		padding: 1rem;
		&__icon {
			margin-right: .7rem;
		}
    }
    &__success {
        background: lighten(green, 70%);
		border: 1px solid green;
		color: green;
    }
}

//switch 
.switchToggle,
.switchToggleSmall{
	height: 0;
	width: 0;
	visibility: hidden;
}
.switchLabel,
.switchLabelSmall {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 26px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}
.switchLabelSmall {
	width: 4rem;
	height: 2rem;
}
.switchLabel:after,
.switchLabelSmall:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}
.switchLabelSmall:after {
	width: 16px;
	height: 16px;
}
.switchToggle:checked + label{
	background: #80b814!important;
}
.switchToggleSmall:checked+label {
	background:#80b814;
}
.switchToggle:checked + label:after,
.switchToggleSmall:checked + label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.switchLabel:active:after {
	//width: 40px;
}